import { FaSignInAlt, FaSignOutAlt, FaUser } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { logout, reset } from '../features/auth/authSlice'

function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth || {})
 
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  return (
    <header className='header'>
      <div className='logo'>
        <Link to='/'>Litterature-API</Link>
      </div>
      <ul>
        {user ? (
            <li>
              <button className='btn' onClick={onLogout}>
                <FaSignOutAlt /> Logout
              </button>
            </li>
          ) : (
            <>
              <li>
                <Link to='/login'>
                  <FaSignInAlt /> Login
                </Link>
              </li>
            </>
        )}
      </ul>
    </header>
  )
}


/* 
{user ? (
    <li>
      <button className='btn' onClick={onLogout}>
        <FaSignOutAlt /> Logout
      </button>
    </li>
  ) : (
    <>
      <li>
        <Link to='/login'>
          <FaSignInAlt /> Login
        </Link>
      </li>
    </>
  )}

   */
export default Header