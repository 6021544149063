import axios from 'axios'

const API_URL = '/api/categories/'


// Get user categories
const getCategories = async () => {
/*   const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      
    },
  } */

  const response = await axios.get(API_URL)

  return response.data
}

const categoryService = {
  getCategories,
}

export default categoryService
