import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
//import GoalForm from '../components/GoalForm'
import CategoriesItem from '../components/CategoriesItem'
import Spinner from '../components/Spinner'
import { getCategories, reset } from '../features/categories/categorySlice'

function Dashboard() {
  
  const[cat, setCat] = useState(null)
  const[articles, setArticles] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector((state) => state.auth || {} )
  const { categories, isLoading, isError, message } = useSelector(
    (state) => state.categories || {})

  useEffect(() => {

    if (isError) {
      console.log('error message: ', message)
    }
    if (!user) {
      navigate('/login')
    }
    // fetch the categories
    
	const fetchCat = async () => {
      const response = await fetch('http://localhost:4000/api/categories', {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", 
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      if(response.ok) {
        setCat(json)
      }
    }
	
    fetchCat()

    // fetch the articles
    const fetchArticles = async () => {
      const response = await fetch('http://localhost:4000/api/articles', {
        method: "GET", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", 
        headers: {
          "Content-Type": "application/json",
        },
      })
      const json = await response.json()
      if(response.ok) {
        setArticles(json)
      }
    }
    fetchArticles()


    //dispatch(getCategories())

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      <section className='heading'>
        <h1>Welcome {user && user.name}</h1>
        <p>categories Dashboard</p>
      </section>


      <section className='content'>
        {cat ? (
          <div className='goal'>
            {cat && cat.map((cat) => (
              <div key={cat._id}>
                {cat.name}
                <p>
                  {cat.description}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <h3>You have not set any categories</h3>
        )}

      </section>
      <section className='content'>
        {cat ? (
          <div className='goal'>
            {articles && articles.map((article) => (
              <div key={article._id}>
                {article.title}
                <p>
                  {article.content}
                </p>
              </div>
            ))}
          </div>
        ) : (
          <h3>You have not set any articles</h3>
        )}

      </section>
    </>
  )
}

export default Dashboard 
